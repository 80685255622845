exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-template-js": () => import("./../../../src/templates/AboutUsTemplate.js" /* webpackChunkName: "component---src-templates-about-us-template-js" */),
  "component---src-templates-blog-category-page-template-js": () => import("./../../../src/templates/BlogCategoryPageTemplate.js" /* webpackChunkName: "component---src-templates-blog-category-page-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-general-template-js": () => import("./../../../src/templates/GeneralTemplate.js" /* webpackChunkName: "component---src-templates-general-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/HomeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-services-template-js": () => import("./../../../src/templates/ServicesTemplate.js" /* webpackChunkName: "component---src-templates-services-template-js" */)
}

